<template>
  <div class="wrap">
    <div class="wraps">
      <!-- 顶部面包屑 -->
      <div class="wrapsTitle">
        <p class="navBack">
          <img
            style="transform: rotate(180deg)"
            src="@/assets/img/kecheng/nextIcon.png"
          /><router-link to="/">返回首页</router-link>
        </p>
        <!-- <img src="@/assets/img/kecheng/nextIcon.png" />
        <span>更多资讯</span> -->
      </div>
      <!-- 顶部图片 -->
      <!-- <div class="wrapsImg">
        <img src="@/assets/img/kecheng/2.png" />
      </div> -->
      <!-- 新闻列表 标题 -->
      <!-- <p>新闻列表</p> -->
      <!-- 新闻列表 内容 -->
      <div class="wrapsCen">
        <!-- 左侧盒子 -->
        <div class="wrapsCenL">
          <div class="filtrate_box">
            <el-radio-group v-model="classifyId" @change="getList" size="small">
              <el-radio-button class="m_b_10" label="全部"></el-radio-button>
              <el-radio-button
                class="m_b_10"
                v-for="(item, index) in btnList"
                :key="index"
                :label="item.id"
              >{{ item.classifyName }}</el-radio-button>
            </el-radio-group>
          </div>
          <!-- 单个详情1 -->
          <div
            class="wrapsCenLs"
            v-for="(item, index) in user"
            :key="index"
            @click="allnewxq(item)"
          >
            <!-- 左边图片 -->
            <div class="wrapsCenLsL">
              <img :src="item.img" />
            </div>
            <!-- 右侧内容 -->
            <div class="wrapsCenLsR">
              <!-- 标题 -->
              <p class="elp">{{ item.title }}</p>
              <!-- 内容 -->
              <div class="wrapsCenLsRCon">
                <div
                  class="contentItem twoEllipsis"
                  v-html="ImgSizeLimit(item.introduction)"
                ></div>
              </div>
              <!-- 底部时间 -->
              <div class="wrapsCenLsRBtm">
                <!-- <img src="@/assets/img/kecheng/time.png" /> -->
                <span>{{ item.addTime }}</span>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30%;
              margin: 20px auto;
              margin-bottom: 20px;
            "
          >
            <el-pagination
              :total="total"
              :current-page="pageNum"
              :page-size="pageSize"
              :page-sizes="[8, 16, 24, 40]"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 右侧盒子 -->
        <div class="wrapsCenR">
          <!-- 模块1 -->
          <div class="wrapsCenRs">
            <p>热门资讯</p>
            <img class="icon" src="@/assets/img/Home/tips.png" />
            <div
              class="elp flexB"
              v-for="(item, index) in newsList.slice(0, 5)"
              :key="index"
              @click="allnewxq(item)"
            >
              <img v-if="item.img" class="itemImg" :src="item.img" alt="" />
              <p class="itemDetails">
                <span
                  :title="item.title"
                  class="itemDetailsTitle twoEllipsis spanLindFeed"
                  >{{ item.title }}</span
                >
                <span class="itemDetailsDesc">{{
                  item.addTime.slice(0, 10)
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryAllArticle, selectClassifyList } from "@/api/home";
export default {
  data() {
    return {
      user: "",

      // 一页几条
      pageSize: 8,
      // 第几页
      pageNum: 1,

      total: 0,
      newsList: [],
      btnList: [],
      classifyId: '全部',
    };
  },
  created() {
    this.getList();
    queryAllArticle("", 14, 1).then((res) => {
      this.newsList = res.rows;
    });
    this.selectClassifyList();
  },
  methods: {
    // 调用新闻资讯接口方法
    getList() {
      queryAllArticle(
        undefined,
        this.pageSize,
        this.pageNum,
        this.classifyId == '全部' ? null : this.classifyId
      ).then((res) => {
        this.user = res.rows;
        this.total = res.total;
      });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    allnewxq(item) {
      this.$router.push({ name: "新闻资讯详情", params: { id: item.id } });
    },
    selectClassifyList() {
      selectClassifyList(1).then((res) => {
        this.btnList = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  // height: 1600px;
  background: #f5f6fa;
  .wraps {
    width: 65%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    // 顶部面包屑
    .wrapsTitle {
      width: auto;
      height: 14px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      p {
        width: auto;
        height: auto;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      img {
        width: 12px;
        height: 12px;
        margin: 0px 10px 0px 10px;
      }
      span {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
    // 顶部图片
    .wrapsImg {
      width: 100%;
      height: 336px;
      margin-top: 20px;
      margin-bottom: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // 新闻列表 标题
    p {
      width: auto;
      height: auto;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #171920;
    }
    // 新闻列表 内容
    .wrapsCen {
      width: 100%;
      padding-bottom: 20px;
      // height: 920px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // 左侧白块
      .wrapsCenL {
        width: 71%;
        height: 100%;
        background: #ffffff;
        padding: 20px;
        // overflow-y: scroll;
        .filtrate_box {
          margin-bottom: 20px;
        }
        // 单个详情
        .wrapsCenLs {
          width: 98%;
          height: 140px;
          margin: 10px auto;
          margin-bottom: 20px;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          cursor: pointer;
          overflow: hidden;
          // 左侧图片
          .wrapsCenLsL {
            width: 25%;
            height: 120px;
            margin-right: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          // 右侧内容
          .wrapsCenLsR {
            width: 73%;
            height: 160px;
            overflow: hidden;
            // 标题
            p {
              width: auto;
              height: auto;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #171920;
            }
            // 内容
            .wrapsCenLsRCon {
              width: auto;
              font-size: 13px;
              margin-top: 13px;
              margin-bottom: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
              line-height: 16px;
              height: 32px;
              overflow: hidden;
            }
            // 底部
            .wrapsCenLsRBtm {
              width: auto;
              height: 12px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 12px;
                height: 12px;
                margin-right: 7px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #a8abbe;
              }
            }
          }
        }
      }
      // 右侧白块
      .wrapsCenR {
        width: 27%;
        // height: 100%;
        background: #ffffff;
        // 单个模块
        .wrapsCenRs {
          width: 85%;
          // height: 185px;
          margin: auto;
          margin-top: 25px;
          // 标题
          p {
            width: auto;
            height: auto;
            // margin-bottom: 20px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #171920;
            margin-left: 12px;
            // margin-left: 12px;
          }
          // 内容
          div {
            width: 100%;
            margin-bottom: 20px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #474747;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.navBack {
  display: flex;
  align-items: center;
}
.flexB {
  display: flex;
  // justify-content: space-between;
}
.itemImg {
  width: 120px;
  height: 80px;
  border-radius: 4px 4px 4px 4px;
  min-width: 120px;
}
.itemDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.itemDetailsTitle {
  font-size: 14px;
  color: #333333;
  width: 154px;
  // height: 50px;
  // display: inline-block;
}
.itemDetailsDesc {
  font-size: 14px;
  color: #999;
}
.icon {
  margin-left: 14%;
  padding-bottom: 20px;
  width: 18px;
}
.contentItem {
  height: 50px;
  overflow: hidden;
}
.m_b_10{
  margin-bottom: 10px;
  margin-right: 5px;
  
}
::v-deep .el-radio-button__inner {
  border: 1px solid #DCDFE6;
  border-radius: 4px !important;
}
</style>
